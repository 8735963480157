// unary functional programming combinators
import type { Checker, CheckResult } from '@recoiljs/refine'

// Identity (take a value and return it)
// const identity = x => x

export const identity = <A>(x: A) => x

// Constant (take a fixed value and always return it)
// export const constant = x => () => x
export const constant = <A>(x: A) =>
  () =>
    x

// Apply (take a function then the arg and run it)
// export const apply = f => x => f(x)
export const apply = <A, B>(f: (x: A) => B) =>
  (x: A): B =>
    f(x)

// Thrush (this is like Apply but backwards)
// export const thrush = x => f => f(x)
export const thrush = <A, B>(x: A) =>
  (f: (x: A) => B): B =>
    f(x)

// Chain
// export const chain = f => g => x => f(g(x))(x)
export const chain = <A, B, C>(f: (y: B) => (x: A) => C) =>
  (g: (x: A) => B) =>
    (x: A): C =>
      f(g(x))(x)
export const thenChain = <A, B, C>(f: (y: B) => (x: A) => C) =>
  (g: (x: A) => Promise<B>) =>
    async (x: A): Promise<C> =>
      f(await g(x))(x)

// Compose (take a function, take another function,
// then run the result of the first function as an arg to the second)
// export const compose = f => g => x => f(g(x))
export const compose = <B, C>(f: (y: B) => C) =>
  <A>(g: (x: A) => B): ((x: A) => C) =>
    (x: A): C =>
      f(g(x))

// Compose2Ary
// export const compose2Ary = f => g => x => y => f(g(x)(y))
export const compose2ary = <B, C>(f: (b: B) => C) =>
  <A, D>(g: (x: A) => (y: D) => B) =>
    (x: A) =>
      (y: D): C =>
        f(g(x)(y))

// Compose3Ary
// export const compose3Ary = f => g => x => y => z => f(g(x)(y)(z))
export const compose3ary = <B, C>(f: (b: B) => C) =>
  <A, D, E>(g: (x: A) => (y: D) => (z: E) => B) =>
    (x: A) =>
      (y: D) =>
        (z: E): C =>
          f(g(x)(y)(z))

// Compose4Ary
// export const compose4Ary = f => g => x => y => z => a => f(g(x)(y)(z)(a))
export const compose4ary = <B, C>(f: (b: B) => C) =>
  <A, D, E, F>(g: (x: A) => (y: D) => (z: E) => (a: F) => B) =>
    (x: A) =>
      (y: D) =>
        (z: E) =>
          (a: F): C =>
            f(g(x)(y)(z)(a))

// Duplication (Take a functon, take an arg, then run the
//   result of the function w/ the arg, as a function on the same arg)
// export const duplication = f => x => f(x)(x)
export const duplication = <A, B>(f: (x: A) => (x: A) => B) =>
  (x: A): B =>
    f(x)(x)

// Flip (take a function and flip the order of the "args", which in FP is the order of invocation)
// export const flip = f => y => x => f(x)(y)
export const flip = <A, B, C>(f: (x: A) => (y: B) => C) =>
  (y: B) =>
    (x: A): C =>
      f(x)(y)

// Substitution
// export const substitution = f => g => x => f(x)(g(x))
export const substitution = <A, B, C>(f: (x: A) => (y: B) => C) =>
  (g: (x: A) => B) =>
    (x: A): C =>
      f(x)(g(x))

// Converge
// export const converge = f => g => h => x => f(g(x))(h(x))
export const converge = <B, C, D>(f: (y: B) => (z: C) => D) =>
  <A>(g: (x: A) => B) =>
    (h: (x: A) => C) =>
      (x: A): D =>
        f(g(x))(h(x))

export const thenConvergeBoth = <B, C, D>(f: (y: B) => (z: C) => D) =>
  <A>(g: (x: A) => Promise<B>) =>
    (h: (x: A) => Promise<C>) =>
      async (x: A): Promise<D> =>
        f(await g(x))(await h(x))
export const thenConvergeLeft = <B, C, D>(f: (y: B) => (z: C) => D) =>
  <A>(g: (x: A) => Promise<B>) =>
    (h: (x: A) => C) =>
      async (x: A): Promise<D> =>
        f(await g(x))(h(x))
export const thenConvergeRight = <B, C, D>(f: (y: B) => (z: C) => D) =>
  <A>(g: (x: A) => B) =>
    (h: (x: A) => Promise<C>) =>
      async (x: A): Promise<D> =>
        f(g(x))(await h(x))

// Psi
// export const psi = f => g => x => y => f(g(x))(g(y))
export const psi = <A, C>(f: (x: A) => (y: A) => C) =>
  <B>(g: (x: B) => A) =>
    (x: B) =>
      (y: B): C =>
        f(g(x))(g(y))
export const thenPsi = <A, C>(f: (x: A) => (y: A) => C) =>
  <B>(g: (x: B) => Promise<A>) =>
    (x: B) =>
      async (y: B): Promise<C> =>
        f(await g(x))(await g(y))

// Sorry about this one in advance...
// Z Combinator (Y Combinator approximation in non-lazy languages via eta abstraction)
// (Achieves recursion)
// export const z = f => (g => g(g))(g => f(x => g(g)(x)))
type R<T> = (x: R<T>) => T // Recursive type - a function accepts type of itself
export type Recursible<I, O> = (f: (x: I) => O) => (x: I) => O // S is for step (in the recursion)
export const fixed = <I, O>(w: Recursible<I, O>) =>
  ((x: R<(x: I) => O>) => w((v) => x(x)(v)))((x: R<(x: I) => O>) => w((v) => x(x)(v)))

// Utility functions for super common problems bringing normal JS into FP
export const construct = <T, U extends unknown[]>(C: new (...x: U) => T) =>
  (...x: U): T =>
    new C(...x)

// Turn non-unary functions into unary functions
export const curry = <A, B, C>(f: (a: A, b: B) => C) =>
  (a: A) =>
    (b: B): C =>
      f(a, b)
export const curry3 = <A, B, C, D>(f: (a: A, b: B, c: C) => D) =>
  (a: A) =>
    (b: B) =>
      (c: C): D =>
        f(a, b, c)
export const curry4 = <A, B, C, D, E>(f: (a: A, b: B, c: C, d: D) => E) =>
  (a: A) =>
    (b: B) =>
      (c: C) =>
        (d: D): E =>
          f(a, b, c, d)

// const thenCompose = f => g => x => f(await g(x)); (async version)
export const thenCompose = <B, C>(f: (j: B) => C) =>
  <A>(g: (i: A) => Promise<B>) =>
    async (x: A): Promise<C> =>
      f(await g(x))

export const caught = <A, B>(f: (x: A) => B) =>
  <C>(g: (e: unknown) => C) =>
    (x: A): B | C => {
      try {
        return f(x)
      } catch (e) {
        return g(e)
      }
    }

export const thenCaught = <A, B>(f: (x: A) => Promise<B>) =>
  <C>(g: (e: unknown) => C) =>
    async (x: A): Promise<B | C> => {
      try {
        return await f(x)
      } catch (e) {
        return g(e)
      }
    }

export const thrown = <A, B>(f: (x: A) => B) =>
  (g: (e: unknown) => unknown) =>
    (x: A): B => {
      try {
        return f(x)
      } catch (e) {
        g(e)
        throw e
      }
    }

export const thenThrown = <A, B>(f: (x: A) => Promise<B>) =>
  (g: (e: unknown) => unknown) =>
    async (x: A): Promise<B> => {
      try {
        return await f(x)
      } catch (e) {
        await g(e)
        throw e
      }
    }

export const fallback = <A, B>(f: (x: A) => B) =>
  <C>(g: (x: A) => C) =>
    (x: A): B | C => {
      try {
        return f(x)
      } catch {
        return g(x)
      }
    }

export const uncurry = <A, B, C>(f: (a: A) => (b: B) => C) =>
  (a: A, b: B) =>
    f(a)(b)
export const uncurry3 = <A, B, C, D>(f: (a: A) => (b: B) => (c: C) => D) =>
  (a: A, b: B, c: C) =>
    f(a)(b)(c)
export const uncurry4 = <A, B, C, D, E>(f: (a: A) => (b: B) => (c: C) => (d: D) => E) =>
  (a: A, b: B, c: C, d: D) =>
    f(a)(b)(c)(d)
export const merge = <A>(l: A) =>
  <B>(r: B): A & B => ({ ...l, ...r })

export const sideEffect = <A>(f: (x: A) => void) =>
  (x: A): A => {
    f(x)
    return x
  }

export type Sifter<U, T extends U = U> = ((x: U) => x is T) | ((x: U) => boolean)

export type Literal = string | number | null | undefined | boolean
export const isLiteral = (o: unknown): o is Literal =>
  typeof o === 'string' || typeof o === 'number' || typeof o === 'boolean' || o === null || o === undefined
export const toLiteral = (o: Literal | unknown): Literal => (isLiteral(o) ? o : JSON.stringify(o))
export const instanceOf = <T, A>(constructor: new (a: A) => T) =>
  (o: unknown): o is T =>
    o instanceof constructor

type ValidKey = string | number | symbol

export const or = <A>(a: A) =>
  <B>(b: B): A | B =>
    a || b

export const nullish = <A>(a: A) =>
  <B>(b: B): NonNullable<A> | B =>
    a ?? b

export const and = <A>(a: A) =>
  <B>(b: B): A | B =>
    a && b

export const eq = (a: unknown) =>
  (b: unknown): boolean =>
    a === b

export const bang = (a: unknown): boolean => !a

export const not = compose(bang)

export const neq = not(eq)

export const lt = (a: number) =>
  (b: number): boolean =>
    b < a

export const gt = (a: number) =>
  (b: number): boolean =>
    b > a

export const lte = (a: number) =>
  (b: number): boolean =>
    b <= a

export const gte = (a: number) =>
  (b: number): boolean =>
    b >= a

export type UnaryFunction<A, B> = (a: A) => B
export type BinaryFunction<A, B, C> = (a: A) => (b: B) => C
export type TernaryFunction<A, B, C, D> = (a: A) => (b: B) => (c: C) => D
export type QuaternaryFunction<A, B, C, D, E> = (a: A) => (b: B) => (c: C) => (d: D) => E

export const defaultedWith = <A>(a: A) =>
  <B>(b: B): A | NonNullable<B> =>
    b ?? a

export const withDefault = <A>(a: A) =>
  <B, C>(f: (b: B) => C) =>
    (b: B): A | NonNullable<C> =>
      f(b) ?? a

export const get = <K extends ValidKey>(key: K) =>
  <T extends Partial<Record<K, unknown>>>(obj: T): T[K] =>
    obj[key]
export const elem = <K extends number>(key: K) =>
  <A, T extends A[]>(obj: T): T[K] =>
    obj[key]
export const wrap = <K extends ValidKey>(k: K) =>
  <V>(v: V): { [K2 in K]: V } =>
    ({ [k]: v } as { [K2 in K]: V })

export const reduce = <A, B>(f: (a: A) => (b: B) => A) =>
  (a: A) =>
    (b: B[]): A =>
      b.reduce(uncurry(f), a)

export const aConcat = <A>(a: A[]) =>
  <B>(b: B[]): (A | B)[] =>
    [...a, ...b]

export const sConcat = (a: string) =>
  (b: string): string =>
    a + b

export const map = <A, B>(f: (a: A) => B) =>
  (a: A[]): B[] =>
    a.map(f)

export const filter = <A>(f: (a: A) => boolean) =>
  (a: A[]): A[] =>
    a.filter(f)

export const split = (a: string) =>
  (b: string): string[] =>
    b.split(a)

export const join = (a: string) =>
  (b: readonly unknown[]): string =>
    b.join(a)

export const indexBy = <
FunctionRange extends ValidKey,
FunctionDomain,
>(f: (x: FunctionDomain) => FunctionRange) =>
    (a: FunctionDomain[]): Record<FunctionRange, FunctionDomain> =>
      reduce(
        (o: Partial<Record<FunctionRange, FunctionDomain>>) =>
          (e: FunctionDomain): Partial<
          Record<FunctionRange, FunctionDomain>> => ({ ...o, [f(e)]: e }),
      )({})(a) as Record<FunctionRange, FunctionDomain>

export const liftToArray = <A>(a: A): A[] => [a]

export const singleton = <A, X>(f: (x: X) => A) =>
  (x: X): [A] =>
    [f(x)]

export const couple = <A, X>(f: (x: X) => A) =>
  <B>(g: (x: X) => B) =>
    (x: X): [A, B] =>
      [f(x), g(x)]

export const triple = <A, X>(f: (x: X) => A) =>
  <B>(g: (x: X) => B) =>
  <C>(h: (x: X) => C) =>
      (x: X): [A, B, C] =>
        [f(x), g(x), h(x)]

export const quadruple = <A, X>(f: (x: X) => A) =>
  <B>(g: (x: X) => B) =>
  <C>(h: (x: X) => C) =>
  <D>(i: (x: X) => D) =>
        (x: X): [A, B, C, D] =>
          [f(x), g(x), h(x), i(x)]

export const fnSpread = <A, B>(f: (a: A) => B) =>
  (a: [A]): B =>
    f(a[0])
export const fnSpread2ary = <A, B, C>(f: (a: A) => (b: B) => C) =>
  (a: [A, B]): C =>
    f(a[0])(a[1])
export const fnSpread3ary = <A, B, C, D>(f: (a: A) => (b: B) => (c: C) => D) =>
  (a: [A, B, C]): D =>
    f(a[0])(a[1])(a[2])
export const fnSpread4ary = <A, B, C, D, E>(f: (a: A) => (b: B) => (c: C) => (d: D) => E) =>
  (a: [A, B, C, D]): E =>
    f(a[0])(a[1])(a[2])(a[3])

/**
 * Build an intersection checker from two checkers.
 * Succeeds only if both checkers succeed.
 * Don't confuse this with an intersection operation on runtime values.
 *
 * Don't want to create a separate module just for this, so it's in here now.
 */
export const intersect = <T1>(c1: Checker<T1>) =>
  <T2>(c2: Checker<T2>) =>
    (v: unknown): CheckResult<T1 & T2> => {
      const r1: CheckResult<T1> = c1(v)
      const r2: CheckResult<T2> = c2(v)
      if (r1.type === 'failure') {
        return r1
      }
      if (r2.type === 'failure') {
        return r2
      }
      const value: T1 & T2 = { ...r1.value, ...r2.value }
      const warnings = [...r1.warnings, ...r2.warnings]
      const type = 'success' as const
      return { type, value, warnings }
    }

export const branch = <A, T extends A = A>(f: Sifter<A, T>) =>
<B>(g: (x: T) => B) =>
<C>(h: (x: A) => C) =>
      (x: T): B | C =>
        (f(x) ? g(x) : h(x))
